<template>
  <div v-if="values" class="w-full relative z-50 min-h-8">
    <div class="overflow-hidden w-full text-xxs text-others-black2">
      <div
        ref="sliderContainer"
        :class="[
          'inline-flex max-w-full overflow-hidden overflow-x-scroll scrollbar-hidden space-x-2 mb-2 transition-all duration-300',
          isSliderMoved ? 'ml-7' : '',
          'md:max-w-[calc(100%-38px)]',
        ]"
        :style="
          criteria === 'color' ? 'padding-right: 28px' : 'padding-right: 24px'
        "
      >
        <span
          :data-href="item.url"
          @click="handleRouter(item.id)"
          class="text-left relative leading-3 w-fit whitespace-nowrap tracking-wider cursor-pointer"
          :class="criteria == 'size' ? 'bg-others-grey py-1.5 px-2' : ''"
          type="button"
          v-for="(item, index) in values"
          :key="index"
          :style="
            index === values.length - 1 && criteria === 'color'
              ? 'margin-right: 4px'
              : ''
          "
        >
          <span
            v-if="criteria == 'size'"
            class="text-xs"
            :class="
              productPresentation &&
              item.value?.trim() === productPresentation.trim()
                ? 'font-bold'
                : 'font-normal'
            "
            >{{ item.value }}</span
          >
          <div
            v-if="criteria == 'color'"
            class="mt-1 w-5 h-5 rounded-full border"
            :style="`background-color: ${item.value}`"
          ></div>
        </span>
      </div>
    </div>

    <button
      type="button"
      @click="handleLeft($event)"
      v-if="isDesktop"
      v-show="showLeft"
      class="h-full w-7 grid place-items-center absolute bottom-0 -left-2 my-auto transition-all duration-300 rounded-tr-md rounded-br-md text-neutral-black-1 cursor-pointer"
      :class="[
        showLeft ? 'opacity-100' : 'opacity-0',
        criteria === 'size' ? '-top-2' : '-top-1',
      ]"
    >
      <img
        src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
        class="h-8"
      />
    </button>

    <button
      type="button"
      @click="handleRight($event)"
      v-if="isDesktop && shouldShowRightArrow"
      v-show="showRight"
      class="h-full w-7 grid place-items-center absolute bottom-0 -right-2 my-auto transition-all duration-300 rounded-tl-md rounded-bl-md text-neutral-black-1 cursor-pointer"
      :class="[
        showRight ? 'opacity-100' : 'opacity-0',
        criteria === 'size' ? '-top-2' : '-top-1',
      ]"
    >
      <img
        src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
        class="h-8"
      />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  productPresentation: String,
  criteria: String,
  values: {
    type: Array,
    required: true,
  },
  url: String,
})

const router = useRouter()
const { isDesktop } = useDevice()
const sliderContainer = ref(null)

const showRight = ref(true)
const showLeft = ref(false)
const isSliderMoved = ref(false)

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

const handleResize = () => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.offsetWidth
  if (
    sliderContainer.value.scrollLeft + containerWidth >=
    sliderContainer.value.scrollWidth
  ) {
    showRight.value = false
  } else {
    showRight.value = true
  }
}

const shouldShowRightArrow = computed(() => {
  if (props.criteria === 'size') {
    return props.values.length > 3
  } else if (props.criteria === 'color') {
    return props.values.length > 7
  }
  return false
})

const handleLeft = (e) => {
  if (!sliderContainer.value) return
  e.preventDefault()
  e.stopPropagation()
  showRight.value = true
  const containerWidth = sliderContainer.value.offsetWidth
  sliderContainer.value.scrollLeft -= containerWidth
  if (sliderContainer.value.scrollLeft <= 0) {
    showLeft.value = false
    isSliderMoved.value = false
  }
}

const handleRight = (e) => {
  if (!sliderContainer.value) return
  e.preventDefault()
  e.stopPropagation()
  showLeft.value = true
  isSliderMoved.value = true
  const containerWidth = sliderContainer.value.offsetWidth

  let extraMargin = 20
  if (props.criteria === 'size' && props.values.length === 6) {
    extraMargin = 10
  }

  sliderContainer.value.scrollLeft += containerWidth - extraMargin

  const tolerance = 10
  if (
    sliderContainer.value.scrollLeft + containerWidth >=
    sliderContainer.value.scrollWidth - tolerance
  ) {
    showRight.value = false
  }
}

const handleRouter = (id) => {
  if (props.values.length > 1) {
    router.push(`${props.url}?variant=${id}`)
    return
  }

  router.push(`${props.url}`)
}
</script>
